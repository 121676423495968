import Vue from "vue";
import VueRouter from "vue-router";
import Guard from "@/router/middleware/guard";

const Home = () => import("../views/Home.vue");

// GENERAL
// const ExampleHome = () => import('../screens/General/ExampleHome.vue')
// const PublicExample = () => import('../screens/General/PublicExample.vue')
const NotFound = () => import("../screens/General/NotFound.vue");
const PermissionDenied = () =>
  import("../screens/General/PermissionDenied.vue");
const AccountPending = () => import("../screens/General/AccountPending.vue");
const Terms = () => import("../screens/General/Terms.vue");
const PrivacyPolicy = () => import("../screens/General/PrivacyPolicy.vue");

// LANDING
const Welcome = () => import("../screens/Landing/Welcome.vue");
const TmpLanding = () => import("../screens/App/TmpLanding.vue");

// AUTH
const AuthLayout = () => import("../screens/Auth/Layout.vue");
const AuthLogin = () => import("../screens/Auth/Login.vue");
const AuthRegister = () => import("../screens/Auth/Register.vue");
const AuthForgotPassword = () => import("../screens/Auth/ForgotPassword.vue");
const AuthResetPassword = () => import("../screens/Auth/ResetPassword.vue");

// APP
const AppLayout = () => import("../screens/App/Layout.vue");
const AppHome = () => import("../screens/App/Home.vue");
const AppSettings = () => import("../screens/App/Settings.vue");
const AppVideos = () => import("../screens/App/Videos.vue");
const AppLive = () => import("../screens/App/Live.vue");

const AppOnboardLayout = () => import("../screens/App/Onboard/Layout.vue");
const AppOnboardBilling = () => import("../screens/App/Onboard/Billing.vue");
const AppOnboardTeam = () => import("../screens/App/Onboard/Team.vue");
const AppOnboardFinalize = () => import("../screens/App/Onboard/Finalize.vue");
const AppOnboardCallback = () => import("../screens/App/Onboard/Callback.vue");

// App Admin
const AdminLayout = () => import("../screens/Admin/Layout.vue");
const AdminHome = () => import("../screens/Admin/Home.vue");

const AppUserSettings = () => import("../screens/App/User/Settings.vue");

// MIDDLEWARE
import auth from "@/router/middleware/auth";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  // LANDING
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  // APP
  {
    path: "/app-redirect",
    name: "AuthRedirect",
    meta: {
      requiresAuth: true,
      requiresVerifiedAccount: true,
    },
    beforeEnter: async (to, from, next) => {
      let hasTeams = await store.getters["auth/userHasTeams"];
      return next({ path: hasTeams ? "/app/home" : "/app/onboard" });
    },
  },
  {
    path: "/app",
    component: AppLayout,
    redirect: "/app-redirect",
    meta: {
      requiresAuth: true,
      requiresVerifiedAccount: true,
    },
    children: [
      {
        path: "home",
        name: "AppHome",
        component: AppHome,
      },
      {
        path: "videos",
        name: "AppVideos",
        component: AppVideos,
      },
      {
        path: "live",
        name: "AppLive",
        component: AppLive,
      },
      {
        path: "settings",
        name: "AppSettings",
        component: AppSettings,
      },
      {
        path: "onboard",
        name: "AppOnboardLayout",
        component: AppOnboardLayout,
        redirect: "/app/onboard/team",
        children: [
          {
            path: "team",
            name: "AppOnboardTeam",
            component: AppOnboardTeam,
          },
          {
            path: "billing",
            name: "AppOnboardBilling",
            component: AppOnboardBilling,
          },
          {
            path: "finalize",
            name: "AppOnboardFinalize",
            component: AppOnboardFinalize,
          },
          {
            path: "callback",
            name: "AppOnboardCallback",
            component: AppOnboardCallback,
          },
        ],
      },
      {
        path: "user/settings",
        name: "AppUserSettings",
        component: AppUserSettings,
      },
    ],
  },
  {
    path: "/admin",
    component: AdminLayout,
    redirect: "/admin/home",
    meta: {
      requiresAuth: true,
      requiresVerifiedAccount: true,
    },
    children: [
      {
        path: "home",
        name: "AdminHome",
        component: AdminHome,
      },
    ],
  },
  // AUTH
  {
    path: "/auth",
    component: AuthLayout,
    meta: {
      preventIfLoggedIn: true,
    },
    children: [
      {
        path: "login",
        name: "AuthLogin",
        component: AuthLogin,
      },
      {
        path: "register",
        name: "AuthRegister",
        component: AuthRegister,
      },
      {
        path: "forgot-password",
        name: "AuthForgotPassword",
        component: AuthForgotPassword,
      },
      {
        path: "reset-password/:token",
        name: "AuthResetPassword",
        component: AuthResetPassword,
      },
    ],
  },
  // GENERAL
  {
    path: "/terms",
    component: Terms,
    name: "GeneralTerms",
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    name: "GeneralPrivacyPolicy",
  },
  {
    path: "/pending",
    component: AccountPending,
  },
  {
    path: "/404",
    component: NotFound,
  },
  {
    path: "/401",
    component: PermissionDenied,
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Check to see if route requires auth, if not bypass
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  if (!requiresAuth) return next();

  // Auth required
  let isAuthenticated = await store.dispatch("auth/checkAuthStatus");
  console.log("> Auth Status ", isAuthenticated);

  if (!isAuthenticated) {
    next({ path: "/auth/login", query: { redirect: to.fullPath } });
  }

  console.log("Auth Information", {
    auth: isAuthenticated,
  });

  next();
});

async function redirectToUserDashboard(next) {
  let userDetails = await store.getters["auth/userInfo"];
  if (userDetails == null || userDetails == undefined) {
    next();
  }
  if (userDetails.role == "admin") {
    next({ path: "/admin/home" });
  }
  if (userDetails.role == "media") {
    next({ path: "/media" });
  }
}

// returns boolean
async function checkRole(roles) {
  if (roles.length == 0) return true;
  let userDetails = await store.getters["auth/userInfo"];
  if (roles.includes("admin") && userDetails.role == "admin") {
    return true;
  }
  if (
    roles.includes("media") &&
    (userDetails.role == "media" || userDetails.role == "admin")
  ) {
    return true;
  }
  if (
    roles.includes("user") &&
    (userDetails.role == "user" || userDetails.role == "admin")
  ) {
    return true;
  }
  return false;
}

export default router;
