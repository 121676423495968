import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import PortalVue from 'portal-vue'

import "./filters";

Vue.config.productionTip = false;

axios.defaults.withCredentials = true;
axios.defaults.headers.common["Accept"] = "application/json";

Vue.http = Vue.prototype.$http = axios;

Vue.use(PortalVue)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
