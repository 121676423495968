import Vue from "vue";
import moment from "moment";

export function displayDateTime(string, format = "M/DD/YY h:mm a") {
  return moment
    .utc(string)
    .local()
    .format(format);
}

export function json(value) {
  return JSON.stringify(value);
}

export function initials(name, delimeter = " ") {
  if (name) {
    var array = name.split(delimeter);

    switch (array.length) {
      case 1:
        return array[0].charAt(0).toUpperCase();
        break;
      default:
        return (
          array[0].charAt(0).toUpperCase() +
          array[array.length - 1].charAt(0).toUpperCase()
        );
    }
  }
  return false;
}

const filters = { displayDateTime, json, initials };

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
