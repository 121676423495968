// More information on Auth: https://blog.sqreen.com/authentication-best-practices-vue/
import axios from "axios";
import router from "../../router";

let STORAGE_TOKEN_KEY = "accessToken";

const state = {
  authenicated: false,
  userData: null,
  teams: [],
};

// getters
const getters = {
  isAuthenticated: (state) => !!state.token,
  hasUserInfo: (state) => !!state.userData,
  userData: (state) => state.userData,
  authStatus: (state) => state.status,

  teams: (state) => state.teams,

  userHasTeams: (state) => !!state.teams && state.teams.length !== 0,
};

// actions
const actions = {
  performLogin: async ({ commit, dispatch }, { email, password, remember }) => {
    await axios.get(process.env.VUE_APP_API_URL + "/sanctum/csrf-cookie");
    let data = {
      email: email,
      password: password,
    };
    if (remember) {
      data["remember"] = "on";
    }
    let res = await axios.post(process.env.VUE_APP_API_URL + "/login", data);
    return res;
  },
  performLogout: async ({ commit, state }) => {
    let res = await axios.post(process.env.VUE_APP_API_URL + "/logout");
    return res.data;
  },
  checkAuthStatus: async ({ commit, state }, accessToken) => {
    console.log(state.authenicated);
    console.log("Checking if is authenicated");
    if (state.authenicated) return true;
    try {
      let res = await axios.get(
        process.env.VUE_APP_API_URL + "/app/authenticated"
      );
      commit("setAuthStatus", true);
      commit("setUserData", res.data);

      // User belongs to teams
      if (res.data.teams) {
        commit("setUserTeams", res.data.teams);
      }
      return true;
    } catch (e) {
      if (e.status == 401) {
        router.push("/auth/login");
      }
      commit("setAuthStatus", false);
      return false;
    }
  },
};

// mutations
const mutations = {
  setAccessToken(state, token) {
    state.token = token;
    localStorage.setItem(STORAGE_TOKEN_KEY, token);
  },
  clearAccessToken(state) {
    state.token = null;
    localStorage.removeItem(STORAGE_TOKEN_KEY);
  },
  setUserData(state, data) {
    state.userData = data;
  },
  setUserTeams(state, data) {
    state.teams = data;
  },
  setAuthStatus(state, data) {
    state.authenicated = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
