<template>
  <div id="app">
    <router-view />
    <portal-target name="modals" multiple></portal-target>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import axios from "axios";
export default {
  name: "App",
  mounted() {
    console.log(process.env.VUE_APP_VERSION);
  },

  // mounted() {
  //   axios.interceptors.response.use(null, (error) => {
  //     console.log("fdsd");
  //     if (error.response.status == 401 || error.response.status == 419) {
  //       // router.push({ path: "/auth/login" });
  //       console.log(router.currentRoute);
  //     }
  //     return Promise.reject(error);
  //   });
  // },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap");
#app {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* #app.modalOpen {
  height: 100vh;
  overflow-y: hidden;
}
*/
.bg-checkered {
  background: rgba(106, 106, 106, 0.83)
    url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" fill-opacity=".25"><rect x="200" width="200" height="200" /><rect y="200" width="200" height="200" /></svg>');
  background-size: 16px 16px;
}
.aspect-ratio-16\/9 {
  padding-bottom: 56.25%;
}
</style>
