// More information on Auth: https://blog.sqreen.com/authentication-best-practices-vue/
import axios from "axios";

const state = {
  id: null,
  name: null,
  subscription_type: null,
  profile_image: null,
  videos: [],
  users: [],
  invitedUsers: [],
  loadingTeamData: false,
  socialAccounts: [],
  recentPosts: [],
  actionRequired: null,
  watermarks: [],
  defaultWatermarkId: null,
  profileImage: null,
};

// getters
const getters = {
  teams: (state) => state.teams,
  users: (state) => state.users,
};

// actions
const actions = {
  getTeamDetails: async ({ commit, state }, id) => {
    console.log(id);
    commit("setLoadingIndicator", true);
    let details = await axios.get(
      process.env.VUE_APP_API_URL + "/app/team/" + id
    );
    commit("setTeamData", details.data);
    console.log(details.data);
    return details.data;
  },
  updateTeamContentSection: async ({ commit, state }, { type, val = null }) => {
    console.log("click");
    if (type == "socialAccounts") {
      let res = await axios.get(
        process.env.VUE_APP_API_URL + "/app/team/" + state.id + "/entities"
      );
      commit("setTeamContent", { type: "socialAccounts", data: res.data });
    }
    if (type == "profileImage") {
      let res = await axios.put(
        process.env.VUE_APP_API_URL +
          "/app/team/" +
          state.id +
          "/settings/profile/image",
        {
          profileImageId: val.id,
        }
      );
      commit("setTeamContent", { type: "profileImage", data: res.data });
    }
  },
};

// mutations
const mutations = {
  setLoadingIndicator(state, status) {
    state.loadingTeamData = true;
  },
  setTeamData(state, data) {
    state.id = data.id;
    state.name = data.name;
    // state.subscription_type = data.subscription_type;
    // state.profile_image = data.profile_image;
    state.users = data.users;
    state.loadingTeamData = false;
    state.socialAccounts = data.social_entities;
    state.recentPosts = data.recent_videos;
    state.actionRequired = data.actionRequiredItems;
    state.watermarks = data.watermarks;
    state.defaultWatermarkId = data.default_watermark_id;
    state.invitedUsers = data.invited_users;
    state.profileImage = data.profile_picture;
  },
  setTeamPosts(state, data) {
    state.posts = data;
  },
  setTeamContent(state, { type, data }) {
    state[type] = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
